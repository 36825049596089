const Spinner = (props: any) => {
    return (
        <div className="justify-center items-center flex flex-col overflow-x-hidden overflow-y-auto fixed inset-0 z-50 bg-black bg-opacity-75">
            <div className="absolute h-16 w-16 rounded-full border-8 border-white text-center">
            </div>
            <div className="absolute w-16 h-16 border-4 border-solid border-t-8 border-b-0 border-l-0 border-r-8 rounded-full animate-spin border-blue-500"></div>
            {props.children}
        </div>
    )
}

export default Spinner