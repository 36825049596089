import React from 'react'
import { Route, Routes } from 'react-router'
import Terms from '../componets/Legal/terms'
import Privacy from '../componets/Legal/privacy'
import Refund from '../componets/Legal/refund'
import Shipping from '../componets/Legal/shipping'

const LegalRouter = () => {
  return (
    <Routes>
        <Route path=''>
            <Route path='/terms' element={<Terms />} />
            <Route path='/privacy' element={<Privacy />} />
            <Route path='/refund' element={<Refund />} />
            <Route path='/shipping' element={<Shipping />} />
        </Route>
    </Routes>
  )
}

export default LegalRouter