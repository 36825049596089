import ServerBadge from "./../elements/DurationBadge"

interface ServerProps {
    banner: string,
    name: string
    HoursLeft?: number
    ServerUUID?: string
    Autorenew?: boolean
    textonhover?: string
}

export const getBanneUrlFromString = (i: string): string => {
    const countryBannerPrefix = "/cdn/assets/country-banners/"
    switch(i) {
      case "miami": 
        return countryBannerPrefix + 'us.svg'
      case "singapore":
        return countryBannerPrefix + 'sg.svg'
      case "singaporebudget":
        return countryBannerPrefix + 'sg.svg'
      case "mumbai":
        return countryBannerPrefix + 'in.svg'
      case "bangalore":
        return countryBannerPrefix + 'in.svg'
      case "hyderabad":
        return countryBannerPrefix + 'in.svg'
      case "hyderabadbudget":
        return countryBannerPrefix + 'in.svg'
      case "germany":
        return countryBannerPrefix + 'de.svg'
      case "netherlands":
        return countryBannerPrefix + 'nl.svg'
      case "newyork":
        return countryBannerPrefix + 'us.svg'
      case "luxembourg-eu":
        return countryBannerPrefix + 'lu.svg'
      case "los_angeles":
        return countryBannerPrefix + "us.svg"
      case "storage_germany":
        return countryBannerPrefix + "de.svg"
      case "bangalore_high_memory":
        return countryBannerPrefix + "in.svg"
      default:
        return `/cdn/assets/game-banners/${i}-banner.png`
    }
}

const Server = ({banner, name, HoursLeft, ServerUUID, Autorenew, textonhover}: ServerProps) => {
    banner = getBanneUrlFromString(banner)
    return (
    <div>
        <div className="gameitemcontainer group cursor-pointer">
            <div className="relative">
              <img src={banner} id="gameitembanner" className="gameitembanner" />
              {textonhover &&
                <div className="absolute top-0 left-0 h-full w-full items-center justify-center z-50 text-2xl font-bold text-white bg-black bg-opacity-75 hidden group-hover:flex">
                  {textonhover}
                </div>
              }
            </div>
            <div id="gameitemtitle" className="uppercase fixed gameitemtitle z-index-0 group-hover:bg-blue-500">{name}</div>
            {(typeof HoursLeft != "undefined" && typeof Autorenew != "undefined") &&
            <ServerBadge HoursLeft={HoursLeft} ServerUUID={ServerUUID} Autorenew={Autorenew} />
            }
        </div>
    </div>
    )
}

export default Server;