import FeatureHeader from "../elements/FeatureHeader";
const GamePanelFeatures = () => {
    return (
        <div className="min-h-screen flex items-center justify-center bg-trueGray-900 py-12 px-4 sm:px-6 lg:px-8">
        <div className="bg-trueGray-900 min-h-screen m-auto text-gray-200 font-bold text-center">
          <section className="text-blueGray-700 ">
            <div className="container flex flex-col items-center px-5 py-8 mx-auto">
              <div className="flex flex-col w-full text-left lg:text-center">
                <h1 className="mx-auto mb-12 text-2xl font-semibold leading-none tracking-tighter text-white lg:w-1/2 sm:text-6xl title-font">Game Panel</h1>
                <p className="mx-auto text-base font-medium leading-relaxed text-gray-500 lg:w-1/2">
                    A Feature rich gamepanel that is meant to imporve productivity and aims at ease of use and is mobile friendly as well    
                </p>
              </div>
            </div>
          </section>
          <FeatureHeader 
            img="/cdn/assets/images/serverslist.png" 
            title="Beautiful UI" 
            text="Beautiful Dark Theme which is plesant on your eyes" 
          />
          <FeatureHeader 
            img="/cdn/assets/images/console.png" 
            title="Console" 
            text="Execute commands and see whos online on your server from the comfort of your browser/mobile" 
          />
          <FeatureHeader 
            img="/cdn/assets/images/filemanager.png" 
            title="Filemanager" 
            text="A Powerful Web based Filemanager where you can create, edit, rename, delete files and folders and also archive and unarchive files" 
          />     
          <FeatureHeader 
            img="/cdn/assets/images/databases.png" 
            title="Databases" 
            text="All Gameservers come with SQL Databases with our special One-Click Backup feature"
          />    
          <FeatureHeader 
            img="/cdn/assets/images/subusers.png" 
            title="Subusers" 
            text="Powerful Subuser system where you can add users and give them granular permissions" 
          />
          <FeatureHeader 
            img="/cdn/assets/images/shedules.png" 
            title="Schedules" 
            text="Schedule your tasks such as power actions or commands to automate your tasks with no code required, ex: Daily restart, Random messages etc" 
          />    
        </div>
        </div>
    );
};

export default GamePanelFeatures;