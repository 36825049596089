// this file was generated DO NOT EDIT
export interface LogoutResponse {
	message: string
	url: string
}
export interface Activity {
	id: number
	server_id: number
	user_id: number
	user: User
	action: string
	description: string
	time: string
	meta_data: string
}
export interface DatabaseHost {
	address: string
	port: number
}
export interface Mtarule {
	Key: string
	Value: string
}
export interface APIResponse<T> {
	success: boolean
	message: string
	data: T
}
export interface PterodactylError {
	code: string
	detail: string
	meta: PterodactylErrorMeta
	status: string
}
export interface GameLocation {
	Location: string
	Units: number
	Ping: string
	StartingFrom: number
}
export interface ClientEggVariable {
	object: string
	attributes: ClientEggVariableAttributes
}
export interface ClientServer {
	attributes: ClientServerAttributes
}
export interface PasswordAttributes {
	password: string
}
export interface Sucesserror {
	complete: boolean
	error: string
}
export interface JustComplete {
	complete: boolean
}
export interface User {
	AccountsID: number
	ID: number
	Email: string
	DiscordID: string
	Credits: number
	AdminLevel: number
}
export interface DBBackup {
	ID: number
	ServerID: number
	Path: string
	Time: string
}
export interface GameServerTransient {
	pServer: ClientServer
}
export interface RenameServerInput {
	name: string
}
export interface PaginatedResponse<T> {
	data: T[]
	pagination_info: PaginationInfo
}
export interface NotificationSetting {
	id: number
	type: string
	mode: string
	value: string
}
export interface Economic {
	Label: string
	Revenue: number
	Expenses: number
	Datapoints: number
	Days: number
}
export interface Database {
	object: string
	attributes: DatabaseAttributes
}
export interface DatabaseRelationships {
	password: DatabasePassword
}
export interface Testimonial {
	User: TestimonialUser
	Time: number
	Message: string
	Platform: string
}
export interface ServerType {
	name: string
	label: string
	avalaible: boolean
	reason: string
}
export interface Gamequery {
	ServerInfo: ServerInfo
	error: string
	onlineplayers: number
	maxplayers: number
	detailed: {[key: string]: string}[]
	pchistogram: HistogramDataPoint[]
	currenttime: number
}
export interface SFTPDetails {
	ip: string
	port: number
}
export interface EggVarAttributes {
	id: number
	egg_id: number
	name: string
	description: string
	env_variable: string
	default_value: string
	user_viewable: boolean
	user_editable: boolean
	rules: string
}
export interface GetGamePanelWebsocketResponse {
	token: string
	socket: string
}
export interface ConfirmOrderInput {
	server_name: string
}
export interface GenericTimeSeries {
	value: any
	time: number
}
export interface TestimonialUser {
	Image: string
	Name: string
}
export interface locationunit {
	cpu: number
	ram: number
	ssd: number
	cost: number
}
export interface ClientGetStartupResponse {
	object: string
	meta: ClientGetStartupMeta
	data: ClientEggVariable[]
}
export interface FileObject {
	object: string
	attributes: FileObjectAttributes
}
export interface MarketingStats {
	Users: number
	Servers: number
	Players: number
	PlayersHistory: HistogramDataPoint[]
	CurrentTime: number
	Testimonials: Testimonial[]
}
export interface GameServer {
	ID: number
	OwnerID: number
	PteroUser: number
	PteroID: number
	PteroUUID: string
	GameType: string
	Name: string
	Location: string
	CPU: number
	RAM: number
	SSD: number
	AutoRenew: boolean
	Active: boolean
	Cost: number
	direct_admin_username: string
	direct_admin_password: string
	ExpiryTime: string
	is_free: boolean
	game_server_transient: GameServerTransient
}
export interface CommandInput {
	raw: string
}
export interface AllocationAttributes {
	id: number
	ip: string
	is_default: boolean
	alias: string
	port: number
	assigned: boolean
}
export interface ClientGetStartupMeta {
	raw_startup_command: string
	startup_command: string
	docker_images: {[key: string]: string}
}
export interface ClientSetEggVariableResponse {
	attributes: ClientEggVariableAttributes
	meta: ClientGetStartupMeta
	errors: PterodactylError[]
}
export interface Meta {
	page: number
	items_per_page: number
	total_items: number
	total_pages: number
}
export interface ServerFeatureLimits {
	databases: number
	allocations: number
	backups: number
}
export interface AuthCheckResponse {
	authenticated: boolean
}
export interface ClientServerAttributes {
	identifier: string
	docker_image: string
	invocation: string
	is_installing: boolean
	is_node_under_maintenance: boolean
	is_suspended: boolean
	is_transferring: boolean
	limits: ServerLimits
	feature_limits: ServerFeatureLimits
	name: string
	node: string
	sftp_details: SFTPDetails
	status: string
	uuid: string
	relationships: ClientServerRelationships
}
export interface ClientServerRelationshipsVariables {
	object: string
	data: EggVariable[]
}
export interface GetAuthorizeResponse {
	url: string
}
export interface Allocation {
	object: string
	attributes: AllocationAttributes
}
export interface Game {
	Name: string
	SLabel: string
	Label: string
	Count: number
	StartsFromCredits: number
}
export interface MonitoringWallBoardSummaryResponse {
	data: GameServer[]
	meta: Meta
	expenses: number
	pterodactyl_nodes: any[]
	last_month: Economic
	running_month: Economic
	detailed_expenses: {[key: string]: number}
}
export interface ClientServerRelationships {
	allocations: ClientServerRelationshipsAllocations
	variables: ClientServerRelationshipsVariables
}
export interface DatabaseAttributes {
	connections_from: string
	host: DatabaseHost
	id: string
	max_connections: number
	name: string
	username: string
	relationships: DatabaseRelationships
}
export interface LinkDiscordInput {
	code: string
}
export interface AuthorizeInputData {
	code: string
}
export interface PteroServerNetworkStats {
	rx_bytes: number
	tx_bytes: number
}
export interface fivemservervars {
	banner_detail: string
	sv_enforceGameBuild: string
	sv_maxClients: string
	sv_projectName: string
	onesync_enabled: string
	sv_projectDesc: string
	tags: string
}
export interface ClientServerRelationshipsAllocations {
	object: string
	data: Allocation[]
}
export interface ChangeGameTypeInput {
	type: string
}
export interface PostChangePlanInput {
	unit: string
}
export interface DockerImageUpdateInput {
	docker_image: string
}
export interface TimeLeftDetailsResponse {
	id: number
	timeleft: string
}
export interface AvaliablityResponse {
	Key: string
	Count: number
}
export interface NotificationSettingsWrappedResponse {
	data: NotificationSetting[]
	pagination_info: PaginationInfo
	Modes: string[]
	Types: string[]
}
export interface PteroCreateDatabaseInput {
	database: string
	remote: string
}
export interface ClientSetEggVariableInput {
	key: string
	value: string
}
export interface ServerInfo {
	Name: string
	OnlinePlayers: number
	MaxPlayers: number
	Extra: {[key: string]: string}
}
export interface GetChangePlanResponse {
	min: number
	max: number
	cpuperunit: number
	ramperunit: number
	ssdperunit: number
	costperunit: number
}
export interface PaginationInfo {
	next_cursor: string
}
export interface EggVariable {
	object: string
	attributes: EggVarAttributes
}
export interface FileObjectAttributes {
	name: string
}
export interface DiscordAuthResponse {
	complete: boolean
	url: string
}
export interface ExportSQLResponse {
	uuid: string
}
export interface PterodactylErrorMeta {
	source_field: string
	rule: string
}
export interface GetOrderParametersResponse {
	Message: string
	LocationUnits: locationunit
	GameMinUnits: number
	AvaliableUnits: number
}
export interface ClientEggVariableAttributes {
	default_value: string
	description: string
	env_variable: string
	is_editable: boolean
	name: string
	rules: string
	server_value: string
}
export interface DatabasePassword {
	object: string
	attributes: PasswordAttributes
}
export interface DatabasesList {
	object: string
	data: Database[]
}
export interface HistogramDataPoint {
	players: number
	time: number
}
export interface ClientCurrencyResponse {
	geo_currency: string
	curriencies: {[key: string]: number}
}
export interface Mtaplayer {
	Nick: string
	Team: string
	Skin: string
	Score: string
	Ping: string
	Time: string
}
export interface Sorter {
	Field: string
	Direction: boolean
}
export interface ServerLimits {
	memory: number
	swap: number
	disk: number
	io: number
	cpu: number
}
import http from './http'
export const GetAuthCheck = (query?: string): Promise<AuthCheckResponse> => {
    return new Promise((resolve, reject) => {
        let q: string = query || ''
        http.get('/auth/check' + q)
            .then(response => {
                return resolve(response.data)
            })
            .catch(reject)
    })
}
export const GetAuthAuthorize = (query?: string): Promise<GetAuthorizeResponse> => {
    return new Promise((resolve, reject) => {
        let q: string = query || ''
        http.get('/auth/authorize' + q)
            .then(response => {
                return resolve(response.data)
            })
            .catch(reject)
    })
}
export const PostAuthAuthorize = (_ip: AuthorizeInputData, query?: string): Promise<number> => {
    return new Promise((resolve, reject) => {
        let q: string = query || ''
        http.post('/auth/authorize' + q, _ip)
            .then(response => {
                return resolve(response.status)
            })
            .catch(reject)
    })
}
export const PostAuthLogout = (query?: string): Promise<LogoutResponse> => {
    return new Promise((resolve, reject) => {
        let q: string = query || ''
        http.post('/auth/logout' + q)
            .then(response => {
                return resolve(response.data)
            })
            .catch(reject)
    })
}
export const GetClientListservertypesByCategory = (category: number, query?: string): Promise<Game[]> => {
    return new Promise((resolve, reject) => {
        let q: string = query || ''
        http.get(`/client/listservertypes/${category}` + q)
            .then(response => {
                return resolve(response.data)
            })
            .catch(reject)
    })
}
export const GetClientGamelocationsByGame = (game: string, query?: string): Promise<GameLocation[]> => {
    return new Promise((resolve, reject) => {
        let q: string = query || ''
        http.get(`/client/gamelocations/${game}` + q)
            .then(response => {
                return resolve(response.data)
            })
            .catch(reject)
    })
}
export const GetClientOrderparametersByGameByLocation = (game: string, location: string, query?: string): Promise<GetOrderParametersResponse> => {
    return new Promise((resolve, reject) => {
        let q: string = query || ''
        http.get(`/client/orderparameters/${game}/${location}` + q)
            .then(response => {
                return resolve(response.data)
            })
            .catch(reject)
    })
}
export const GetClientMarketingstats = (query?: string): Promise<MarketingStats> => {
    return new Promise((resolve, reject) => {
        let q: string = query || ''
        http.get('/client/marketingstats' + q)
            .then(response => {
                return resolve(response.data)
            })
            .catch(reject)
    })
}
export const GetClientCurrency = (query?: string): Promise<ClientCurrencyResponse> => {
    return new Promise((resolve, reject) => {
        let q: string = query || ''
        http.get('/client/currency' + q)
            .then(response => {
                return resolve(response.data)
            })
            .catch(reject)
    })
}
export const GetClientFreeAvaliablity = (query?: string): Promise<AvaliablityResponse[]> => {
    return new Promise((resolve, reject) => {
        let q: string = query || ''
        http.get('/client/free/avaliablity' + q)
            .then(response => {
                return resolve(response.data)
            })
            .catch(reject)
    })
}
export const GetClientAccount = (query?: string): Promise<User> => {
    return new Promise((resolve, reject) => {
        let q: string = query || ''
        http.get('/client/account' + q)
            .then(response => {
                return resolve(response.data)
            })
            .catch(reject)
    })
}
export const GetClientServers = (query?: string): Promise<GameServer[]> => {
    return new Promise((resolve, reject) => {
        let q: string = query || ''
        http.get('/client/servers' + q)
            .then(response => {
                return resolve(response.data)
            })
            .catch(reject)
    })
}
export const GetClientLinkdiscord = (query?: string): Promise<DiscordAuthResponse> => {
    return new Promise((resolve, reject) => {
        let q: string = query || ''
        http.get('/client/linkdiscord' + q)
            .then(response => {
                return resolve(response.data)
            })
            .catch(reject)
    })
}
export const PostClientUnlinkdiscord = (query?: string): Promise<JustComplete> => {
    return new Promise((resolve, reject) => {
        let q: string = query || ''
        http.post('/client/unlinkdiscord' + q)
            .then(response => {
                return resolve(response.data)
            })
            .catch(reject)
    })
}
export const PostClientLinkdiscord = (_ip: LinkDiscordInput, query?: string): Promise<JustComplete> => {
    return new Promise((resolve, reject) => {
        let q: string = query || ''
        http.post('/client/linkdiscord' + q, _ip)
            .then(response => {
                return resolve(response.data)
            })
            .catch(reject)
    })
}
export const PostClientGetroles = (query?: string): Promise<JustComplete> => {
    return new Promise((resolve, reject) => {
        let q: string = query || ''
        http.post('/client/getroles' + q)
            .then(response => {
                return resolve(response.data)
            })
            .catch(reject)
    })
}
export const PostClientConfirmorderByGameByLocationByUnits = (game: string, location: string, units: number, _ip: ConfirmOrderInput, query?: string): Promise<Sucesserror> => {
    return new Promise((resolve, reject) => {
        let q: string = query || ''
        http.post(`/client/confirmorder/${game}/${location}/${units}` + q, _ip)
            .then(response => {
                return resolve(response.data)
            })
            .catch(reject)
    })
}
export const GetClientNotification_settings = (query?: string): Promise<NotificationSettingsWrappedResponse> => {
    return new Promise((resolve, reject) => {
        let q: string = query || ''
        http.get('/client/notification_settings' + q)
            .then(response => {
                return resolve(response.data)
            })
            .catch(reject)
    })
}
export const PostClientNotification_settings = (_ip: NotificationSetting, query?: string): Promise<NotificationSetting> => {
    return new Promise((resolve, reject) => {
        let q: string = query || ''
        http.post('/client/notification_settings' + q, _ip)
            .then(response => {
                return resolve(response.data)
            })
            .catch(reject)
    })
}
export const PutClientNotification_settingsById = (id: number, _ip: NotificationSetting, query?: string): Promise<NotificationSetting> => {
    return new Promise((resolve, reject) => {
        let q: string = query || ''
        http.put(`/client/notification_settings/${id}` + q, _ip)
            .then(response => {
                return resolve(response.data)
            })
            .catch(reject)
    })
}
export const DeleteClientNotification_settingsById = (id: number, query?: string): Promise<number> => {
    return new Promise((resolve, reject) => {
        let q: string = query || ''
        http.delete(`/client/notification_settings/${id}` + q)
            .then(response => {
                return resolve(response.status)
            })
            .catch(reject)
    })
}
export const PostClientFreeClaimByType = (type: string, query?: string): Promise<Sucesserror> => {
    return new Promise((resolve, reject) => {
        let q: string = query || ''
        http.post(`/client/free/claim/${type}` + q)
            .then(response => {
                return resolve(response.data)
            })
            .catch(reject)
    })
}
export const GetServerByServerid = (serverid: string, query?: string): Promise<GameServer> => {
    return new Promise((resolve, reject) => {
        let q: string = query || ''
        http.get(`/server/${serverid}` + q)
            .then(response => {
                return resolve(response.data)
            })
            .catch(reject)
    })
}
export const GetServerByServeridMetricsByType = (serverid: string, type: string, query?: string): Promise<GenericTimeSeries[]> => {
    return new Promise((resolve, reject) => {
        let q: string = query || ''
        http.get(`/server/${serverid}/metrics/${type}` + q)
            .then(response => {
                return resolve(response.data)
            })
            .catch(reject)
    })
}
export const GetServerByServeridChangegametype = (serverid: string, query?: string): Promise<ServerType[]> => {
    return new Promise((resolve, reject) => {
        let q: string = query || ''
        http.get(`/server/${serverid}/changegametype` + q)
            .then(response => {
                return resolve(response.data)
            })
            .catch(reject)
    })
}
export const GetServerByServeridChangeplan = (serverid: string, query?: string): Promise<GetChangePlanResponse> => {
    return new Promise((resolve, reject) => {
        let q: string = query || ''
        http.get(`/server/${serverid}/changeplan` + q)
            .then(response => {
                return resolve(response.data)
            })
            .catch(reject)
    })
}
export const GetServerByServeridGetgpws = (serverid: string, query?: string): Promise<GetGamePanelWebsocketResponse> => {
    return new Promise((resolve, reject) => {
        let q: string = query || ''
        http.get(`/server/${serverid}/getgpws` + q)
            .then(response => {
                return resolve(response.data)
            })
            .catch(reject)
    })
}
export const GetServerByServeridPlayers = (serverid: string, query?: string): Promise<Gamequery> => {
    return new Promise((resolve, reject) => {
        let q: string = query || ''
        http.get(`/server/${serverid}/players` + q)
            .then(response => {
                return resolve(response.data)
            })
            .catch(reject)
    })
}
export const PostServerByServeridRenew = (serverid: string, query?: string): Promise<Sucesserror> => {
    return new Promise((resolve, reject) => {
        let q: string = query || ''
        http.post(`/server/${serverid}/renew` + q)
            .then(response => {
                return resolve(response.data)
            })
            .catch(reject)
    })
}
export const PostServerByServeridChangegametype = (serverid: string, _ip: ChangeGameTypeInput, query?: string): Promise<Sucesserror> => {
    return new Promise((resolve, reject) => {
        let q: string = query || ''
        http.post(`/server/${serverid}/changegametype` + q, _ip)
            .then(response => {
                return resolve(response.data)
            })
            .catch(reject)
    })
}
export const PostServerByServeridChangeplan = (serverid: string, _ip: PostChangePlanInput, query?: string): Promise<Sucesserror> => {
    return new Promise((resolve, reject) => {
        let q: string = query || ''
        http.post(`/server/${serverid}/changeplan` + q, _ip)
            .then(response => {
                return resolve(response.data)
            })
            .catch(reject)
    })
}
export const PostServerByServeridAutorenew = (serverid: string, query?: string): Promise<Sucesserror> => {
    return new Promise((resolve, reject) => {
        let q: string = query || ''
        http.post(`/server/${serverid}/autorenew` + q)
            .then(response => {
                return resolve(response.data)
            })
            .catch(reject)
    })
}
export const PostServerByServeridRename = (serverid: string, _ip: RenameServerInput, query?: string): Promise<Sucesserror> => {
    return new Promise((resolve, reject) => {
        let q: string = query || ''
        http.post(`/server/${serverid}/rename` + q, _ip)
            .then(response => {
                return resolve(response.data)
            })
            .catch(reject)
    })
}
export const PostServerByServeridReinstall = (serverid: string, query?: string): Promise<Sucesserror> => {
    return new Promise((resolve, reject) => {
        let q: string = query || ''
        http.post(`/server/${serverid}/reinstall` + q)
            .then(response => {
                return resolve(response.data)
            })
            .catch(reject)
    })
}
export const GetServerByServeridStartup = (serverid: string, query?: string): Promise<ClientGetStartupResponse> => {
    return new Promise((resolve, reject) => {
        let q: string = query || ''
        http.get(`/server/${serverid}/startup` + q)
            .then(response => {
                return resolve(response.data)
            })
            .catch(reject)
    })
}
export const PutServerByServeridStartupVaraible = (serverid: string, _ip: ClientSetEggVariableInput, query?: string): Promise<ClientSetEggVariableResponse> => {
    return new Promise((resolve, reject) => {
        let q: string = query || ''
        http.put(`/server/${serverid}/startup/varaible` + q, _ip)
            .then(response => {
                return resolve(response.data)
            })
            .catch(reject)
    })
}
export const PutServerByServeridStartupDockerimage = (serverid: string, _ip: DockerImageUpdateInput, query?: string): Promise<number> => {
    return new Promise((resolve, reject) => {
        let q: string = query || ''
        http.put(`/server/${serverid}/startup/dockerimage` + q, _ip)
            .then(response => {
                return resolve(response.status)
            })
            .catch(reject)
    })
}
export const PutServerByServeridReset_password = (serverid: string, query?: string): Promise<GameServer> => {
    return new Promise((resolve, reject) => {
        let q: string = query || ''
        http.put(`/server/${serverid}/reset_password` + q)
            .then(response => {
                return resolve(response.data)
            })
            .catch(reject)
    })
}
export const GetServerByServeridNotification_settings = (serverid: string, query?: string): Promise<NotificationSettingsWrappedResponse> => {
    return new Promise((resolve, reject) => {
        let q: string = query || ''
        http.get(`/server/${serverid}/notification_settings` + q)
            .then(response => {
                return resolve(response.data)
            })
            .catch(reject)
    })
}
export const PostServerByServeridNotification_settings = (serverid: string, _ip: NotificationSetting, query?: string): Promise<NotificationSetting> => {
    return new Promise((resolve, reject) => {
        let q: string = query || ''
        http.post(`/server/${serverid}/notification_settings` + q, _ip)
            .then(response => {
                return resolve(response.data)
            })
            .catch(reject)
    })
}
export const PutServerByServeridNotification_settingsById = (serverid: string, id: number, _ip: NotificationSetting, query?: string): Promise<NotificationSetting> => {
    return new Promise((resolve, reject) => {
        let q: string = query || ''
        http.put(`/server/${serverid}/notification_settings/${id}` + q, _ip)
            .then(response => {
                return resolve(response.data)
            })
            .catch(reject)
    })
}
export const DeleteServerByServeridNotification_settingsById = (serverid: string, id: number, query?: string): Promise<number> => {
    return new Promise((resolve, reject) => {
        let q: string = query || ''
        http.delete(`/server/${serverid}/notification_settings/${id}` + q)
            .then(response => {
                return resolve(response.status)
            })
            .catch(reject)
    })
}
export const GetServerByServeridAutodatabasebackup = (serverid: string, query?: string): Promise<DBBackup[]> => {
    return new Promise((resolve, reject) => {
        let q: string = query || ''
        http.get(`/server/${serverid}/autodatabasebackup` + q)
            .then(response => {
                return resolve(response.data)
            })
            .catch(reject)
    })
}
export const GetServerByServeridAutodatabasebackupByBackupid = (serverid: string, backupid: string, query?: string): Promise<number> => {
    return new Promise((resolve, reject) => {
        let q: string = query || ''
        http.get(`/server/${serverid}/autodatabasebackup/${backupid}` + q)
            .then(response => {
                return resolve(response.status)
            })
            .catch(reject)
    })
}
export interface ActivitySearchCritCondition {
    field: 'id' | 'server_id' | 'user_id' | 'user' | 'action' | 'description' | 'time' | 'meta_data'
    condition: 'equals' | 'not equals' | 'contains' | 'not contains' | 'greater than' | 'lesser than'
    value: any
}
export const GetServerByServeridActivities = (serverid: string, query?: string, filter?: ActivitySearchCritCondition[]): Promise<PaginatedResponse<Activity>> => {
    return new Promise((resolve, reject) => {
        let q: string = query || ''
        if (filter && filter.length > 0) {
            q + (q != '' && '&' || '?') + 'filter=' + encodeURI(JSON.stringify(filter))
        }
        http.get(`/server/${serverid}/activities` + q)
            .then(response => {
                return resolve(response.data)
            })
            .catch(reject)
    })
}
export const PostServerByServeridPower_actionByAction = (serverid: string, action: string, query?: string): Promise<number> => {
    return new Promise((resolve, reject) => {
        let q: string = query || ''
        http.post(`/server/${serverid}/power_action/${action}` + q)
            .then(response => {
                return resolve(response.status)
            })
            .catch(reject)
    })
}
export const PostServerByServeridSend_command = (serverid: string, _ip: CommandInput, query?: string): Promise<number> => {
    return new Promise((resolve, reject) => {
        let q: string = query || ''
        http.post(`/server/${serverid}/send_command` + q, _ip)
            .then(response => {
                return resolve(response.status)
            })
            .catch(reject)
    })
}
export const GetServerByServeridNetwork = (serverid: string, query?: string): Promise<DatabasesList> => {
    return new Promise((resolve, reject) => {
        let q: string = query || ''
        http.get(`/server/${serverid}/network` + q)
            .then(response => {
                return resolve(response.data)
            })
            .catch(reject)
    })
}
export const GetServerByServeridDatabases = (serverid: string, query?: string): Promise<DatabasesList> => {
    return new Promise((resolve, reject) => {
        let q: string = query || ''
        http.get(`/server/${serverid}/databases` + q)
            .then(response => {
                return resolve(response.data)
            })
            .catch(reject)
    })
}
export const PostServerByServeridDatabases = (serverid: string, _ip: PteroCreateDatabaseInput, query?: string): Promise<APIResponse<Database>> => {
    return new Promise((resolve, reject) => {
        let q: string = query || ''
        http.post(`/server/${serverid}/databases` + q, _ip)
            .then(response => {
                return resolve(response.data)
            })
            .catch(reject)
    })
}
export const PostServerByServeridDatabasesByPtero_database_idExport = (serverid: string, ptero_database_id: string, query?: string): Promise<APIResponse<string>> => {
    return new Promise((resolve, reject) => {
        let q: string = query || ''
        http.post(`/server/${serverid}/databases/${ptero_database_id}/export` + q)
            .then(response => {
                return resolve(response.data)
            })
            .catch(reject)
    })
}
export const PostServerByServeridDatabasesByPtero_database_idRotate_password = (serverid: string, ptero_database_id: string, query?: string): Promise<APIResponse<Database>> => {
    return new Promise((resolve, reject) => {
        let q: string = query || ''
        http.post(`/server/${serverid}/databases/${ptero_database_id}/rotate_password` + q)
            .then(response => {
                return resolve(response.data)
            })
            .catch(reject)
    })
}
export const DeleteServerByServeridDatabasesByPtero_database_id = (serverid: string, ptero_database_id: string, query?: string): Promise<number> => {
    return new Promise((resolve, reject) => {
        let q: string = query || ''
        http.delete(`/server/${serverid}/databases/${ptero_database_id}` + q)
            .then(response => {
                return resolve(response.status)
            })
            .catch(reject)
    })
}
export const PostServerByServeridReset_timer = (serverid: string, query?: string): Promise<number> => {
    return new Promise((resolve, reject) => {
        let q: string = query || ''
        http.post(`/server/${serverid}/reset_timer` + q)
            .then(response => {
                return resolve(response.status)
            })
            .catch(reject)
    })
}
export interface UserSearchCritCondition {
    field: 'AccountsID' | 'ID' | 'Email' | 'DiscordID' | 'Credits' | 'AdminLevel'
    condition: 'equals' | 'not equals' | 'contains' | 'not contains' | 'greater than' | 'lesser than'
    value: any
}
export const GetAdminUsers = (query?: string, filter?: UserSearchCritCondition[]): Promise<PaginatedResponse<User>> => {
    return new Promise((resolve, reject) => {
        let q: string = query || ''
        if (filter && filter.length > 0) {
            q + (q != '' && '&' || '?') + 'filter=' + encodeURI(JSON.stringify(filter))
        }
        http.get('/admin/users' + q)
            .then(response => {
                return resolve(response.data)
            })
            .catch(reject)
    })
}
export interface GameServerSearchCritCondition {
    field: 'ID' | 'OwnerID' | 'PteroUser' | 'PteroID' | 'PteroUUID' | 'GameType' | 'Name' | 'Location' | 'CPU' | 'RAM' | 'SSD' | 'AutoRenew' | 'Active' | 'Cost' | 'direct_admin_username' | 'direct_admin_password' | 'ExpiryTime' | 'is_free' | 'game_server_transient'
    condition: 'equals' | 'not equals' | 'contains' | 'not contains' | 'greater than' | 'lesser than'
    value: any
}
export const GetAdminServers = (query?: string, filter?: GameServerSearchCritCondition[]): Promise<PaginatedResponse<GameServer>> => {
    return new Promise((resolve, reject) => {
        let q: string = query || ''
        if (filter && filter.length > 0) {
            q + (q != '' && '&' || '?') + 'filter=' + encodeURI(JSON.stringify(filter))
        }
        http.get('/admin/servers' + q)
            .then(response => {
                return resolve(response.data)
            })
            .catch(reject)
    })
}
export const GetAdminMonitoringwallboardsummary = (query?: string): Promise<MonitoringWallBoardSummaryResponse> => {
    return new Promise((resolve, reject) => {
        let q: string = query || ''
        http.get('/admin/monitoringwallboardsummary' + q)
            .then(response => {
                return resolve(response.data)
            })
            .catch(reject)
    })
}
export const GetAdminGameservermetrics = (query?: string): Promise<number> => {
    return new Promise((resolve, reject) => {
        let q: string = query || ''
        http.get('/admin/gameservermetrics' + q)
            .then(response => {
                return resolve(response.status)
            })
            .catch(reject)
    })
}
export const GetGamepanelGameserverqueryByGametypeByIpport = (gametype: string, ipport: string, query?: string): Promise<Gamequery> => {
    return new Promise((resolve, reject) => {
        let q: string = query || ''
        http.get(`/gamepanel/gameserverquery/${gametype}/${ipport}` + q)
            .then(response => {
                return resolve(response.data)
            })
            .catch(reject)
    })
}
export const GetGamepanelExportsqlByEndpointByUsernameByPasswordByDatabasename = (endpoint: string, username: string, password: string, databasename: string, query?: string): Promise<ExportSQLResponse> => {
    return new Promise((resolve, reject) => {
        let q: string = query || ''
        http.get(`/gamepanel/exportsql/${endpoint}/${username}/${password}/${databasename}` + q)
            .then(response => {
                return resolve(response.data)
            })
            .catch(reject)
    })
}
export const GetGamepanelDownloadexportedsqlByUuid = (uuid: string, query?: string): Promise<number> => {
    return new Promise((resolve, reject) => {
        let q: string = query || ''
        http.get(`/gamepanel/downloadexportedsql/${uuid}` + q)
            .then(response => {
                return resolve(response.status)
            })
            .catch(reject)
    })
}
export const GetGamepanelDetailsByUuid = (uuid: string, query?: string): Promise<TimeLeftDetailsResponse> => {
    return new Promise((resolve, reject) => {
        let q: string = query || ''
        http.get(`/gamepanel/details/${uuid}` + q)
            .then(response => {
                return resolve(response.data)
            })
            .catch(reject)
    })
}
