import { Loader } from "@mantine/core"
import { useEffect } from "react"
import { useLocation, useNavigate } from "react-router"
import { PostAuthAuthorize } from "../../api/api.gen"

const AuthCallback = () => {
    const location = useLocation()
    const navigate = useNavigate()
    let query = new URLSearchParams(location.search);
    useEffect(() => {
        var codeParam = query.get("code")
        if (codeParam != null) {
            PostAuthAuthorize({code: codeParam}).then(() => {
                navigate(query.get("redirect") || "/", {replace: true})
            })
        }   
    }, [])

    return (
        <div className="w-screen h-screen flex items-center justify-center">
            <Loader size="xl" />
        </div>
    )
}

export default AuthCallback