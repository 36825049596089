const Shipping = () => {
	return (
		<div className='min-h-screen flex items-center justify-center bg-trueGray-900 py-12 px-4 sm:px-6 lg:px-8'>
			<div className='bg-trueGray-900 min-h-screen m-auto text-gray-200 font-bold text-center'>
				<div className='font-extrabold text-6xl text-white mb-5'>Shipping & Delivery Policy</div>
				<div className='my-10 mx-8'>
					All services offered and products sold are of digital nature hence are mostly created and deployed
					for your use in a matter of minutes
				</div>
				<div>In few cases such as customizations, and other addons</div>
				<div>where we might aquire and setup additional system would require additional time to deploy</div>
			</div>
		</div>
	)
}

export default Shipping
