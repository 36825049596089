import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faRedoAlt, faExclamationTriangle, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

interface DurationBadgeProps {
    HoursLeft: number
    ServerUUID?: string
    Autorenew: boolean
}

const ServerBadge = ({HoursLeft, Autorenew}: DurationBadgeProps) => {
    var TimeLeft = HoursLeft
    var Duration = ` ${TimeLeft} H `
    var symbol = faClock
    var txtcolor = "#059669"
    var bgcolor = "#A7F3D0"

    if (TimeLeft<0) {
        symbol = faTrashAlt
        var txtcolor = "#DC2626"
        var bgcolor = "#FECACA"
        TimeLeft = TimeLeft + 72
    } else if (TimeLeft < 168) {
        symbol = faExclamationTriangle
        var txtcolor = "#D97706"
        var bgcolor = "#FDE68A"
    } 

    if (TimeLeft>24){
        let day = Math.floor(TimeLeft / 24)
        let hour = Math.floor(TimeLeft % 24)
        Duration = ` ${day} D ${hour} H `
    } else {
        Duration = ` ${TimeLeft} H `
    }
     
    return (
    <>
        <span style={{ backgroundColor: bgcolor, color: txtcolor }} className="border relative bottom-56 right-28 text-xs font-semibold inline-block py-1 px-2 rounded-full text-white bg-green-500 bg-opacity-50 uppercase mb-2 last:mr-0 mr-1">
            <FontAwesomeIcon icon={symbol} />
            {Duration}
            {Autorenew &&
                <FontAwesomeIcon icon={faRedoAlt} />
            }
        </span>
    </>
    )
}

export default ServerBadge;