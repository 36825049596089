import ServerListCard from './ServerListCard'

export interface TestServer {
    Type: string
    Endpoint: string
    Location: string
    Name?: string
    Link?: string
}

const testServers: TestServer[] = [
    // Singapore
    {Type: "samp", Endpoint: "sg0.snhcloud.com:7775", Link: "samp://sg0.snhcloud.com:7775", Location: "sg"},
    {Type: "samp", Endpoint: "sg0.snhcloud.com:7791", Link: "samp://sg0.snhcloud.com:7791", Name: "SAMP (Open.mp)", Location: "sg"},
    {Type: "mta", Endpoint: "sg0.snhcloud.com:30125", Link: "mtasa://sg0.snhcloud.com:30125", Location: "sg"},
    // Mumbai
    {Type: "samp", Endpoint: "in4.snhcloud.com:7777", Link: "samp://in4.snhcloud.com:7777", Location: "in"},
    {Type: "fivem", Endpoint: "connect z3yel9", Link: "https://servers.fivem.net/servers/detail/z3yel9", Location: "in"},
    // Hyderabad
    {Type: "minecraft", Endpoint: "mc.snhcloud.com", Location: "in"},
    // Germany
    {Type: "fivem", Endpoint: "connect a8ybg9", Link: "https://servers.fivem.net/servers/detail/a8ybg9", Location: "de"},
]

const ServersList = () => {
    return (
        <div className="flex justify-center bg-trueGray-900 py-12 px-4 sm:px-6 lg:px-8">
            <div className="flex flex-col items-center text-gray-200 font-bold text-center w-screen">
                <h1>Game Servers List</h1>
                <p className="text-gray-400">These are servers hosted trial or community servers,  Cannot find the server in a specific region your looking for ? just reach out to us via discord to claim your free trial</p>
                <div className="flex flex-wrap gap-2 mt-12 justify-center">
                    {testServers.map(ts => (
                        <ServerListCard ts={ts} />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default ServersList