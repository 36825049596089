import { useEffect, useState } from 'react'
import Card from '../componets/elements/Card'
import { notifications } from '@mantine/notifications'
import { AvaliablityResponse, GetClientFreeAvaliablity, PostClientFreeClaimByType } from '../api/api.gen'
import { useNavigate } from 'react-router'

const ClaimFreeGameServers = () => {

  const Navigate = useNavigate()
  const [FreeAvaliablity, setFreeAvaliablity] = useState<AvaliablityResponse[]>([])
  useEffect(() => {
    GetClientFreeAvaliablity().then(resp => {
      setFreeAvaliablity(resp)
    })
  }, [])
  

  const onFreeServerClaimAttempt = (gameType: string) => {
    PostClientFreeClaimByType(gameType).then(resp => {
      notifications.show({
        color: resp.complete ? 'green' : 'red',
        title: resp.complete ? 'Success' : 'Error',
        message: resp.complete ? 'Claimed!' : resp.error,
      })
      Navigate("/dashboard/servers")
    }).catch(resp => {
      notifications.show({
        color: 'red',
        title: 'Error',
        message: 'Make sure your logged in, you can only claim one free game server',
      })
    })
  }

  const getAvaliablityCount = (gameType: string) => {
    for (let index = 0; index < FreeAvaliablity.length; index++) {
      const fa = FreeAvaliablity[index];
      if (fa.Key == gameType) {
        if (fa.Count == 0) {
          return -1
        }
        return fa.Count
      }
    }
    return -2
  }

  return (
    <div className="flex items-center justify-center flex-col">
      <h1 className="text-center pt-2">Free Game Server Hosting</h1>
      <div className="grid  grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 lg:px-20 my-8">
        <div onClick={() => onFreeServerClaimAttempt("samp")}>
          <Card name="Free SAMP Game Server Hosting" banner="samp" extra={getAvaliablityCount("samp")} />
        </div>
        <div onClick={() => onFreeServerClaimAttempt("mta")}>
          <Card name="Free MTA:SA Game Server Hosting" banner="mta" extra={getAvaliablityCount("mta")} />
        </div>
      </div>
      <div>
        <p>- Your server must have "SNHCloud.com" in its name</p>
        <p>- Your server must not be password protected</p>
        <p>- Only community support</p>
        <p>- Free servers are located in Germany</p>
        <p>- Your server must not exceed the slot limit defined below</p>
        <p>SAMP - 30 Player slots</p>
        <p>MTA  - 30 Player slots</p>
      </div>
    </div>
  )
}

export default ClaimFreeGameServers