const Terms = () => {
    return (
        <>
        <div className="min-h-screen flex items-center justify-center bg-trueGray-900 py-12 px-4 sm:px-6 lg:px-8">
          <div className="bg-trueGray-900 min-h-screen m-auto text-gray-200 font-bold text-center">
            <div className="font-extrabold text-6xl text-white mb-5">Terms of Service</div>
            <code className="bg-blue-500 rounded-lg p-1 text-white">Last Updated on: 9th Apirl 2021</code>
            <div className="my-10 mx-8">
              These Terms of Service are agreement between "snhcloud.com" ("us" or "our" or "Super Nova") and you ("User" or "you").
      This Agreement sets terms and conditions of your use of the products and services made available by "us" and of the "snhcloud.com" website.
      By using "our" Services, you agree to accept this Agreement. If you do not agree to the Agreement, you are not authorized to use the Services.
      By agreeing to our Terms of Service you also agree to our 
                <div className="flex gap-2 justify-center">
                  <a
                    href='/legal/privacy'
                    className='text-gray-400 hover:text-blue-500'
                  >
                    Privacy Policy
                  </a>
                  <a
                    href='/legal/refund'
                    className='text-gray-400 hover:text-blue-500'
                  >
                    Refund and cancellation
                  </a>
                  <a
                    href='/legal/shipping'
                    className='text-gray-400 hover:text-blue-500'
                  >
                    Shipping & Delivery Policy
                  </a>
                </div>
            </div>
            <div className="my-10 mx-8">
              <h2 className="text-blue-500 text-2xl">Ordering</h2>
                <h5 className="text-xl">1. The customer is not obliged to make the payment after placing an order.</h5>
                <h5 className="text-xl">2. The customer is not committed to anything and can stop paying at any time.</h5>
                <h5 className="text-xl">3. Orders will be delivered immediately unless there is not enough stock.</h5>
                <h5 className="text-xl">4. SuperNova will do its atmost to ensure that there is always enough stock.</h5>
              <h2 className="text-blue-500 text-2xl">Services</h2>
                <h5 className="text-xl">1. Any malicous use of our services such and not limited to port scanning, DDoS, DoS attacks, acessing unauthorized networks, API abuse, hosting or using illegal or pirated content is not allowed, and your services will be terminated with out any futher notice</h5>
                <h5 className="text-xl">2. The customer is responsible for making backups of his own servers.</h5>

              <h2 className="text-blue-500 text-2xl">Game servers</h2>
                <h5 className="text-xl">1. If the consumer does not renew the server, SuperNova is authorized to shut down the server.</h5>
                <h5 className="text-xl">2. If the consumer does not renew the server, SuperNova is authorized to remove all data of this server after 3 days.</h5>
                <h5 className="text-xl">3. Support with regard to plugins/resources and code is not always guaranteed due to its complexity.</h5>
                <h5 className="text-xl">3. Your Game server must follow the approriate terms and conditons of the perticular game.</h5>

              <h2 className="text-blue-500 text-2xl">Obligations of Super Nova</h2>
                <h5 className="text-xl">1. SuperNova is not responsible for the content that the customer is hosting.</h5>
                <h5 className="text-xl">2. SuperNova will remove illegal content or other illegal applications/scripts that are not permitted by law.</h5>
                <h5 className="text-xl">3. SuperNova is not responsible for data-loss in any case.</h5>
                <h5 className="text-xl">4. Super Nova reserves the right to suspend your account or services if any of our Terms of service has been violated with out any Prior Notice</h5>
                <h5 className="text-xl">5. Super Nova reserves the right to add and edit conditions without notification.</h5>   

              <h2 className="text-blue-500 text-2xl">Support</h2>
                <h5 className="text-xl">1. The customer can open a ticket in our discord channel if he has any questions regarding his service.</h5>
                <h5 className="text-xl">2. The official (non-SLA) response time of SuperNova is 24 hours. But in practice this can of course be a lot faster.</h5>
                <h5 className="text-xl">3. SuperNova only offers support on the services provided by SuperNova. SuperNova does not provide support for third-party software.</h5>

              <h2 className="text-blue-500 text-2xl">Refunds</h2>
                <h5 className="text-xl">1. The customer is entitled to a refund if the services of SuperNova have an availability of less than 99%</h5>
                <h5 className="text-xl">2. The customer is entitled to a refund if the services of SuperNova are having technical issues for a long period of time.</h5>
                <h5 className="text-xl">3. The customer needs to report a technical issue the the SuperNova support and give SuperNova enough time to fix the issue.</h5>
                <h5 className="text-xl">4. A refund will be denied if the reason for the refund is: "I don't know how it works".</h5>
                <h5 className="text-xl">5. A refund will be processed within 7 business days.</h5>
                <h5 className="text-xl">6. Some countries have a legal period in which the customer can terminate their server without giving any reason. The customer then gets his money back. This is called Right of withdrawal. By placing an order with SuperNova, you agree to waive this right.</h5>
                <h5 className="text-xl">7. The customer may not refund the payment, through payment services such as paypal by submitting a claim. They must always request a refund via the SuperNova support.</h5>
                <h5 className="text-xl">8. If the customer opens a claim with paypal, SuperNova may immediately stop the services of this customer and charge €25, for administration costs.</h5>

              <h2 className="text-blue-500 text-2xl">Force of the majority</h2>
                <h5 className="text-xl">1. None of the parties is obliged to fulfill any obligation if it is prevented from doing so due to force majeure.</h5>
                <h5 className="text-xl">Force majeure means in any case: war (danger), riots, strikes, war, fire, water damage, flooding, atmospheric conditions,</h5>
                <h5 className="text-xl">prolonged power outages, adjustments or maintenance to the telecommunications network and / or electricity network of others,</h5>
                <h5 className="text-xl">cable breaks, attacks on a network / server including DDoS and DoS, disruptions in the networks important for , disruptions</h5>
                <h5 className="text-xl">in communicative connections including telecommunication connections or prevention or refusal / prolonged absence of compliance by</h5>
                <h5 className="text-xl">suppliers on whom  depends on the performance of its work is.</h5>
            </div>
          </div>
        </div>
        </>
    )
}

export default Terms;