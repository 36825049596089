import { faCaretSquareUp, faExternalLinkSquareAlt, faLink, faWifi } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'

const Footer = () => {
	return (
		<div className='items-center w-full h-full bg-black overflow-y-hidden'>
			<footer className='text-blueGray-700 transition duration-500 ease-in-out transform'>
				<div className='container flex flex-col flex-wrap p-8 mx-auto md:items-center lg:items-start md:flex-row md:flex-no-wrap '>
					<div className='flex-shrink-0 w-64 mx-auto text-center md:mx-0 md:text-left'>
						<div className="flex gap-5 flex-col items-center justify-center">
							<div className='flex flex-col gap-2 text-center'>
								<div className="flex gap-1 items-center justify-center">
									<img
										width='104'
										height='40'
										loading="lazy"
										src='https://snhcloud.com/cdn/snh_no_padding.webp'
										alt="SNH Cloud logo"
									/>
									<p className='text-white font-semibold'>
										Super Nova Host
									</p>
								</div>
								<p className='text-xs font-semibold tracking-widest text-white uppercase text-center title-font '>
									Reliable Yet Affordable Hosting
								</p>
								<p className='text-xs tracking-widest text-gray-400 text-center title-font '>
									© 2020-2024 All Rights Reserved
								</p>
							</div>
							<div className="flex flex-col gap-2 justify-center items-center">
								<Link to="contactus" className="font-extrabold text-md text-white cursor-pointer">Contact us</Link>
								<p className="flex gap-1">
									<strong>Email:</strong>
									<a href="mailto:support@snhcloud.com" className='text-blue-500 hover:text-blue-400 focus:text-blue-400 hover:underline highlight ring-blue-500'>support@snhcloud.com</a>
								</p>
								<div className="flex justify-around w-full items-center gap-1">
									<a href="https://discord.gg/MU6SVtzUek" target="_blank">
										<img width={40} height={40} loading="lazy" src="https://snhcloud.com/cdn/assets/discord-mark-blue.svg" />
									</a>
									<a href="https://status.snhcloud.com" target="_blank">
										<FontAwesomeIcon icon={faWifi} className="text-2xl text-blue-500" />
									</a>
								</div>
							</div>
						</div>
					</div>
					<div className='flex flex-wrap flex-grow mt-8 md:pl-20 md:mt-0 gap-2 md:gap-0'>
						<div className='w-full lg:w-1/3 md:w-1/2'>
							<p className='mb-2 font-bold text-lg text-blue-500'>Create a Game Server</p>
							<nav className='flex flex-col gap-2 list-none'>
								<Link
									to='/hosting/game-servers/fivem'
									className='text-gray-400 hover:text-blue-500'
								>
									FiveM Server Hosting
								</Link>
								<Link
									to='/hosting/game-servers/minecraftjava'
									className='text-gray-400 hover:text-blue-500'
								>
									Minecraft Server Hosting
								</Link>
								<Link
									to='/hosting/game-servers/ark'
									className='text-gray-400 hover:text-blue-500'
								>
									ARK Survival Evolved Server Hosting
								</Link>
								<Link
									to='/hosting/game-servers/csgo'
									className='text-gray-400 hover:text-blue-500'
								>
									CSGO Server Hosting
								</Link>
								<Link
									to='/hosting/game-servers/unturned'
									className='text-gray-400 hover:text-blue-500'
								>
									Unturned Server Hosting
								</Link>
								<Link
									to='/hosting/game-servers/tf2'
									className='text-gray-400 hover:text-blue-500'
								>
									Team Fotress 2 Server Hosting
								</Link>
								<Link
									to='/hosting/game-servers/7d2d'
									className='text-gray-400 hover:text-blue-500'
								>
									7 days to die Server Hosting
								</Link>
							</nav>
						</div>
						<div className='w-full lg:w-1/3 md:w-1/2'>
							<p className='mb-2 font-bold text-lg text-blue-500'>Other Game Servers</p>
							<nav className='flex flex-col gap-2 list-none'>
								<Link
									to='/hosting/game-servers/samp'
									className='text-gray-400 hover:text-blue-500'
								>
									San Andreas Multiplayer (SAMP) Server Hosting
								</Link>
								<Link
									to='/hosting/game-servers/spaceengineers'
									className='text-gray-400 hover:text-blue-500'
								>
									Space Engineers Server Hosting
								</Link>
								<Link
									to='/hosting/game-servers/mta'
									className='text-gray-400 hover:text-blue-500'
								>
									Multi Theft Auto (MTASA) Server Hosting
								</Link>
								<Link
									to='/hosting/game-servers/altv'
									className='text-gray-400 hover:text-blue-500'
								>
									AltV Server Hosting
								</Link>
								<Link
									to='/hosting/game-servers/ragemp'
									className='text-gray-400 hover:text-blue-500'
								>
									RageMP Server Hosting
								</Link>
								<Link
									to='/hosting/game-servers/gtac'
									className='text-gray-400 hover:text-blue-500'
								>
									GTA Connected Server Hosting
								</Link>
								<Link
									to='/hosting/game-servers/vcmp'
									className='text-gray-400 hover:text-blue-500'
								>
									Vice City Multiplayer Server Hosting
								</Link>
							</nav>
						</div>
						<div className='w-full lg:w-1/3 md:w-1/2'>
							<p className='mb-2 font-bold text-lg text-blue-500'></p>
							<nav className='flex flex-col gap-2 list-none'>
								<Link
									to='/hosting/game-servers'
									className='text-gray-400 hover:text-blue-500'
								>
									Game Server Hosting
								</Link>
								<Link
									to='/hosting/website/directadmin/luxembourg-eu'
									className='text-gray-400 hover:text-blue-500'
								>
									Website Hosting (Direct Admin)
								</Link>
								<Link
									to='/game-panel-features'
									className='text-gray-400 hover:text-blue-500'
								>
									Game panel features
								</Link>
								<Link
									to='/game-servers-list'
									className='text-gray-400 hover:text-blue-500'
								>
									Game servers list
								</Link>
								{/* <Link
									to='/game-server-query-checker'
									className='text-gray-400 hover:text-blue-500'
								>
									Game Server Query Checker
								</Link> */}
								<a
									href='/legal/terms'
									className='text-gray-400 hover:text-blue-500'
								>
									Terms & Conditions
								</a>
							</nav>
						</div>
					</div>
				</div>
			</footer>
		</div>
	)
}

export default Footer
