import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ServerBadge from "./../elements/DurationBadge"
import { faSignal } from "@fortawesome/free-solid-svg-icons"
import { useEffect, useState } from "react"
import { getBanneUrlFromString } from "../Dashboard/Server"

interface CardProps {
    banner: string
    name: string
    extra?: number
    selected?: boolean
    ping?: string
    subtitle?: string
    setDataUp?: (name: string, val: number) => void
}

const Card = ({banner, name, extra, selected, ping, subtitle, setDataUp}: CardProps) => {
    const [Latency, setLatency] = useState(0)
    useEffect(() => {
        let ws: WebSocket
        if (ping && ping != "") {
            ws = new WebSocket(ping);
        
            let pingCount = 0;
            let pingTimes: number[] = [];
        
            ws.addEventListener('open', () => {
                const pingInterval = setInterval(() => {
                    if (pingCount >= 10) {
                        clearInterval(pingInterval);
                        const lowestTime = Math.min(...pingTimes);
                        setLatency(lowestTime);
                        setDataUp && setDataUp(name, lowestTime)
                    } else if (ws.readyState === WebSocket.OPEN) {
                        const startTime = Date.now();
                        ws.send(startTime + "");
                        ws.addEventListener('message', () => {
                            const rtt = Date.now() - startTime;
                            pingTimes.push(rtt);
                            setLatency(pv => {
                                if (pv < rtt && pv != 0) {
                                    return pv
                                } else {
                                    return rtt
                                }
                            });
                            pingCount++;
                        });
                    }
                }, 1000);
            });
        }
        
        return () => {
            if (ping && ping != "") {
                ws.close();
            }
        };
    }, []);
    
    let pingColor = "text-green-500"
    if (ping) {
        if (Latency > 250) {
            pingColor = "text-red-500"
        } else if (Latency > 150) {
            pingColor = "text-yellow-500"
        }
    }
    banner = getBanneUrlFromString(banner)

    return (
    <div>
        <div className="flex flex-col border border-blue-500 group">
            <img src={banner} />
            <div className={"px-2 py-1 text-white group-hover:bg-blue-500 flex flex-row justify-around items-center gap-2" + (selected ? " bg-blue-500" : " bg-black")}>
                <div className="flex flex-col items-center justify-center">
                    <p className="text-sm font-medium">{name}</p>
                    {extra != -2 &&
                    <>    
                        {extra == -1 ?
                            <div className="flex justify-center items-center font-medium text-sm">
                                <div className="w-2 h-2 bg-red-600 rounded-full mr-1" />
                                <div>Out of stock</div>
                            </div>
                        :
                            <div className="flex justify-center items-center font-medium text-sm">
                                <div className="w-2 h-2 bg-green-600 rounded-full mr-1" />
                                <div>{extra}</div>
                            </div>
                        }
                    </>
                    }
                    {subtitle &&
                        <div className="text-sm text-center">
                            {subtitle}
                        </div>
                    }
                </div>
                {Latency > 0 &&
                    <div className={"flex justify-center gap-1 items-center font-medium text-sm " + pingColor}>
                        <div>~{Latency} ms</div>
                    </div>
                }
            </div>
        </div>
    </div>
    )
}

export default Card;