import { ClientCurrencyResponse, GameServer } from '@/api/api.gen';
import { ConsoleLine, PterodactylWSStats } from '@/componets/Dashboard/gameserver/Console';
import { DEFAULT_BASE_CURRENCY } from '@/utils/util';
import { MD5 } from 'crypto-js';
import create from 'zustand';
import { devtools } from 'zustand/middleware'

export interface UserData {
    Email: string
    gravatar?: string
    AdminLevel?: number
    DiscordID?: string
    Credits?: number
    AccountsID: number
    ID: number
}

interface GlobalState {
    selectedCurrency: string
    setSelectedCurrency: (sc: string) => void
    warning: string
    setWarning: (w: string) => void
    currencyMeta: ClientCurrencyResponse
    setCurrencyMeta: (cm: ClientCurrencyResponse) => void
    loggedInUser: UserData | undefined
    setLoggedInUser: (liu: UserData | undefined) => void
    // mostly related to current game server
    currentGameServer: GameServer | undefined
    setCurrentGameServer: (gs: GameServer | undefined) => void
    currentGameServerSocket: WebSocket | undefined
    setCurrentGameServerSocket: (w: WebSocket | undefined) => void
    currentTerminalLines: ConsoleLine[]
    setCurrentTerminalLines: (l: ConsoleLine[]) => void
    appendCurrentTerminalLines: (l: ConsoleLine) => void
    currentGameServerStats: PterodactylWSStats | undefined
    setCurrentGameServerStats: (p: PterodactylWSStats | undefined) => void
    currentGameServerStatsHistory: any[]
    setCurrentGameServerStatsHistory: (p: any[]) => void
    appendCurrentGameServerStatsHistory: (p: PterodactylWSStats) => void
}

const useGlobalState = create<GlobalState>()(
    devtools(
        (set) => ({
            warning: '',
            setWarning: (w: string) => set(() => ({warning: w})),
            selectedCurrency: "INR", //DEFAULT_BASE_CURRENCY
            setSelectedCurrency: (sc: string) => set(() => ({selectedCurrency: sc})),
            // DEFAULT_BASE_CURRENCY
            currencyMeta: {geo_currency: "INR", curriencies: {DEFAULT_BASE_CURRENCY: 1}},
            setCurrencyMeta: (cc: ClientCurrencyResponse) => set(() => ({currencyMeta: cc})),
            loggedInUser: undefined,
            setLoggedInUser: (liu: UserData | undefined) => set(() => {
                if (liu) {
                    liu.gravatar = MD5(liu.Email).toString()
                }
                return {loggedInUser: liu}
            }),
            // mostly related to current game server
            currentGameServer: undefined,
            setCurrentGameServer: (gs: GameServer | undefined) => set(() => ({currentGameServer: gs})),
            currentGameServerSocket: undefined,
            setCurrentGameServerSocket: (w: WebSocket | undefined) => set(() => ({currentGameServerSocket: w})),
            currentTerminalLines: [],
            setCurrentTerminalLines: (l: ConsoleLine[]) => set(() => ({currentTerminalLines: l})),
            appendCurrentTerminalLines: (l: ConsoleLine) => set((pv) => ({currentTerminalLines: pv.currentTerminalLines.concat(l)})),
            currentGameServerStats: undefined,
            setCurrentGameServerStats: (p: PterodactylWSStats | undefined) => set(() => ({currentGameServerStats: p})),
            currentGameServerStatsHistory: [],
            setCurrentGameServerStatsHistory: (p: any[]) => set(() => ({currentGameServerStatsHistory: p})),
            appendCurrentGameServerStatsHistory: (stats: PterodactylWSStats) => set((pv) => ({currentGameServerStatsHistory: [...pv.currentGameServerStatsHistory, stats]}))
        })        
    )
)

export default useGlobalState