
import { useNavigate } from "react-router";
import {useLocation} from "react-router-dom";
import { GetAuthAuthorize, GetClientAccount } from '../../api/api.gen';
import useGlobalState from "@/state";


const Login = () => {
  const navigate = useNavigate()
  const loggedInUser = useGlobalState(state => state.loggedInUser);
  const setLoggedInUser = useGlobalState(state => state.setLoggedInUser);
  let query = new URLSearchParams(useLocation().search);
  if (loggedInUser == undefined) {
      var isAuthenticated = false
      GetClientAccount().then(response => {
          setLoggedInUser(response)
          isAuthenticated = true
          navigate(query.get("redirect") || "/dashboard")
      }).catch(() => {}).finally(() => {
          if (!isAuthenticated) {
            GetAuthAuthorize().then(response => {
              window.open(response.url + "&redirect=" + (query.get("redirect") || "/dashboard"), "_self")
            })
          }
      })
  }

  return (<></>);
};

export default Login;