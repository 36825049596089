const ContactUs = () => {
	return (
		<>
			<div className='min-h-screen flex items-center justify-center bg-trueGray-900 py-12 px-4 sm:px-6 lg:px-8'>
				<div className='bg-trueGray-900 min-h-screen m-auto text-gray-200 font-bold text-center'>
					<div className='font-extrabold text-6xl text-white mb-5'>Contact Us</div>
					<div className='my-10 mx-8'>
						For support and other quries you can contact us via our discord server or mail us to the below
						mail
					</div>
					<div className='my-10 mx-8'>Email address: support@snhcloud.com</div>
				</div>
			</div>
		</>
	)
}

export default ContactUs
