interface FeatureHeaderProps {
    title: string,
    text: string,
    img: string,
    direction?: string
}

const FeatureHeader = ({img, title, text, direction}: FeatureHeaderProps) => {
    if (direction) {
      return (
          <section >
            <div className="container flex flex-col px-5 py-12 mx-auto lg:items-center md:flex-row lg:px-28">
              <div className="w-full mb-10 lg:w-5/6 lg:max-w-lg md:w-1/2">
                <img className="transform scale-125 object-cover object-center rounded" alt="hero" loading="lazy" src={img} />
              </div>
              <div className="flex flex-col items-start text-left lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16">
                <h1 className="mb-8 text-2xl tracking-tighter text-left text-white lg:text-5xl title-font">{title}</h1>
                <p className="mb-8 text-base leading-relaxed text-left text-gray-500 ">{text}</p>
              </div>
            </div>
          </section>
        )
    } else {
      return (
        <section>
          <div className="container flex flex-col items-center px-5 py-12 mx-auto md:flex-row lg:px-28">
            <div className="flex flex-col items-start mb-16 text-left lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 md:mb-0">
              <h1 className="mb-8 text-2xl tracking-tighter text-white lg:text-5xl title-font">{title}</h1>
              <p className="mb-8 text-base leading-relaxed text-left text-gray-500 ">{text}</p>
            </div>
            <div className="w-full lg:w-5/6 lg:max-w-lg md:w-1/2">
              <img className="transform scale-125 object-cover object-center rounded-lg" alt="hero" src={img} />
            </div>
          </div>
        </section>
      )
    }
    
}

export default FeatureHeader;