import useGlobalState from '@/state'
import { Allocation, GameServer } from '../api/api.gen'
import { differenceInMinutes } from 'date-fns'

const getTimeAgoString = (time: number) => {
	let s = 0
	let m = 0
	let h = 0
	s = time
	if (s >= 60.0) {
		m = Math.floor(s / 60.0)
		s = s % 60.0
	}
	if (m >= 60.0) {
		h = Math.floor(m / 60)
		m = m % 60.0
	}
	let result = `${s.toFixed(0)}s ago`
	if (h >= 1) {
		result = `${h.toFixed(0)}h${m.toFixed(0)}m ago`
	} else if (m >= 1) {
		result = `${m.toFixed(0)}m ago`
	}
	return result
}

export const capitalizeWords = (inputString: string) => {
	return inputString.replace(/\b\w/g, match => match.toUpperCase());
}

export const getExpiryTime = (server: GameServer, oldUnits: number, newUnits: number) => {
	var timestamp = new Date(server.ExpiryTime)
	var timenow = Date.now()
	var timeinmins = differenceInMinutes(timestamp, timenow)
	let hour = Math.floor(timeinmins / 60)
	hour = Math.floor(hour * (oldUnits / newUnits))
	var onlyHours = hour
	let mins = Math.floor(timeinmins % 60)
	let result = ` ${hour} H ${mins} M `
	if (hour > 24) {
		let day = Math.floor(hour / 24)
		let hours = Math.floor(hour % 24)
		let minz = Math.floor(mins)
		result = ` ${day} D ${hours} H ${minz} M`
	}
	return {string: result, hours: onlyHours}
}

export const getMemoryDenotationFromBytes = (bytes: number) => {
	var i = -1
	var byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB']
	do {
		bytes /= 1024
		i++
	} while (bytes > 1024)

	return Math.max(bytes, 0.1).toFixed(2) + byteUnits[i]
}

export const DEFAULT_BASE_CURRENCY = "INR"

export const getCurrencyTranslated = (num: number, selectedCurrency: string): string => {
	let currencyMeta = useGlobalState.getState().currencyMeta

	return `${(num * currencyMeta.curriencies[selectedCurrency]).toFixed(2)} ${selectedCurrency}`
}

export const getCurrencyTranslatedWithCredits = (num: number, selectedCurrency: string): string => {
	return `${getCurrencyTranslated(num, selectedCurrency)} ${selectedCurrency != DEFAULT_BASE_CURRENCY ? `| ${num} Credits (₹ INR)` : ""}`
}

export default getTimeAgoString

export const getDefaultAllocationAsString = (gs: GameServer | undefined): string => {
	if (gs) {
		let allocations = gs.game_server_transient.pServer.attributes.relationships.allocations.data
		for (let index = 0; index < allocations.length; index++) {
			const element = allocations[index];
			if (element.attributes.is_default) {
				return element.attributes.alias || element.attributes.ip + ":" + element.attributes.port
			}
		}
	}
	return ""
}