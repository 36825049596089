const Privacy = () => {
    return (
        <>
        <div className="min-h-screen flex items-center justify-center bg-trueGray-900 py-12 px-4 sm:px-6 lg:px-8">
          <div className="bg-trueGray-900 min-h-screen m-auto text-gray-200 font-bold text-center">
            <div className="font-extrabold text-6xl text-white mb-5">Privacy Policy</div>
            <code className="bg-blue-500 rounded-lg p-1 text-white">Last Updated on: 9th Apirl 2021</code>
            <div className="my-10 mx-8">
              <h2 className="text-blue-500 text-2xl">This page contains our policies regarding collection, use and disclosure of personal information</h2>
                <h5 className="text-xl">By using our services, you agree to accept this policy. If you do not agree, you are not authorized to use the services.</h5>
              <h2 className="text-blue-500 text-2xl">Information/Data data we collect may include:</h2>
                <h5 className="text-xl">- Email, IP address, browser information.</h5>
                <h5 className="text-xl">- We never save any payment personal data such as credit card information, Paypal data, etc. (That may be collected by our third party providers).</h5>
                <h5 className="text-xl">- We do not knowingly permit children under the age of 13 to register an account.</h5>
              <h2 className="text-blue-500 text-2xl">The Collected information/data may be used for the following:</h2>
                <h5 className="text-xl">- Payment validation.</h5>
                <h5 className="text-xl">- Portal.</h5>
                <h5 className="text-xl">- Email promotions.</h5>
                <h5 className="text-xl">- Statistical purposes.</h5>
              <h2 className="text-blue-500 text-2xl">Retention of collected data</h2>
                <h5 className="text-xl">- Data will be stored in our database for an undefined period of time.</h5>
              <h2 className="text-xl">Your rights</h2>
                <h5 className="text-xl">- Request a copy of your personal data.</h5>
                <h5 className="text-xl">- Request that we hide your personal data from our Portal.</h5>
                <h5 className="text-xl">- Request to stop receiving emails.</h5>
                <h5 className="text-xl">- Update or change your personal data.</h5>
                <h5 className="text-xl">Please note that these rights may be limited, and we may take the right to refuse requests on exceptions if they pose a risk.</h5>
              <h2 className="text-blue-500 text-2xl">Cookies</h2>
                <h5 className="text-xl">Our website uses cookies to provide the best experience to our users. By using our services you agree to use of cookies. Cookies are files with a small amount of data, which may include an unique identifier.</h5>
                <h5 className="text-xl">Our privacy policy may change in the future. Notable changes might be published on our website or notified through email.</h5>
            </div>    
        </div>
        </div>
        </>
    )
}

export default Privacy;