import './App.css'
import { Outlet } from 'react-router'

import Footer from './componets/Footer'
import TopNav from './componets/TopNav'
import { GetClientAccount } from './api/api.gen'
import useGlobalState from './state'

function App() {
	const loggedInUser = useGlobalState(state => state.loggedInUser)
	const setLoggedInUser = useGlobalState(state => state.setLoggedInUser)
	if (loggedInUser == undefined) {
		GetClientAccount().then(response => {
			setLoggedInUser(response)
		}).catch(() => {})
	}
	return (
		<div className='flex flex-col min-h-screen bg-black overflow-y-hidden'>
			<TopNav />
			<div className='flex-grow'>
				<Outlet />
			</div>
			<Footer />
		</div>
	)
}

export default App
