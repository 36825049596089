import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGamepad,faBookOpen, faDatabase, faBars, faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { Select } from '@mantine/core';
import { GetClientCurrency } from '../api/api.gen';
import { DEFAULT_BASE_CURRENCY } from '../utils/util';
import useGlobalState from '@/state';

interface NavLinksProps {
    mobile?: boolean
    setNavState?: (arg: boolean) => void
}

const NavLinks = ({mobile, setNavState}: NavLinksProps) => {
    const loggedInuser = useGlobalState(state => state.loggedInUser)
    const currencyMeta = useGlobalState(state => state.currencyMeta)
    const setCurrencyMeta = useGlobalState(state => state.setCurrencyMeta)
    const selectedCurrency = useGlobalState(state => state.selectedCurrency)
    const setSelectedCurrency = useGlobalState(state => state.setSelectedCurrency)
    const direction = mobile && "col" || "row"

    const CloseNav = () => {
        if (setNavState) {
            setNavState(false)
        }
    }

    useEffect(() => {
        GetClientCurrency().then(resp => {
            setCurrencyMeta(resp)
            setSelectedCurrency(resp.geo_currency)
        })
    }, [])

    useEffect(() => {
        //@ts-ignore
        if (loggedInuser && window.Tawk_API && window.Tawk_API.setAttributes && window.Tawk_API.onLoaded) {
            //@ts-ignore
            window.Tawk_API.setAttributes({
                name: loggedInuser.Email,
                email: loggedInuser.Email,
                id: loggedInuser.ID,
                accounts_id: loggedInuser.AccountsID,
            }, function(err: any) {
                console.log(err)
            });
        }
    }, [loggedInuser])
    

    return (
        <div className={`flex-grow flex flex-${direction} items-center justify-between gap-5 mx-5`} >
            <div className={`flex flex-${direction} items-center gap-5 justify-center font-bold font-sans`} >
                <Link 
                    className='flex gap-2 items-center justify-center hover:text-red-500'
                    to="/hosting"
                    onClick={CloseNav}
                >
                    <FontAwesomeIcon icon={faGamepad} />
                    <p>Game servers</p>
                </Link>
                <a className='flex gap-2 items-center justify-center hover:text-black'
                href="https://snhcloud.com/docs" 
                target="_blank"
                onClick={CloseNav}
                >
                    <FontAwesomeIcon icon={faBookOpen} />
                    <p>Docs</p>    
                </a>
                <a className='flex gap-2 items-center justify-center hover:text-blue-500'
                href="https://sql.snhcloud.com"
                target="_blank"
                onClick={CloseNav}
                >
                    <FontAwesomeIcon icon={faDatabase} />
                    <p>PHPMyAdmin</p>
                </a>
            </div>
            <div className={"flex gap-5 " + (mobile ? "" : `self-end`)}>
                <div className={`flex flex-${direction} gap-5 items-center justify-center font-sans font-bold`} >
                <div className="flex gap-3 items-center justify-center">
                    <div>Currency:</div>
                    <Select 
                        searchable
                        variant="filled" 
                        value={selectedCurrency} 
                        //@ts-ignore
                        data={Object.keys(currencyMeta?.curriencies || {})} 
                        onChange={(v) => setSelectedCurrency(v || DEFAULT_BASE_CURRENCY)}
                    />
                </div>
                    {loggedInuser ?
                        <>
                            {loggedInuser.AdminLevel !=0 &&
                            <Link to="/dashboard/admin" onClick={CloseNav}>
                                Admin
                            </Link>
                            }
                            <Link 
                                className='flex gap-2 items-center justify-center hover:text-red-500'
                                to="/dashboard"
                                onClick={CloseNav}
                            >
                                <FontAwesomeIcon icon={faLayerGroup} />
                                <p>Services</p>
                            </Link>
                            <Link to="/dashboard/account" onClick={CloseNav}>
                                <img className="rounded-full text-center" src={`https://gravatar.com/avatar/${loggedInuser?.gravatar}?s=40`} />
                            </Link>
                        </>
                    :
                        <Link to="/login" onClick={CloseNav} className="font-bold hover:text-blue-500">
                            Login
                        </Link>
                    }
                </div>
            </div>
        </div>
    )
}

const TopNav = () => {
    const [MenuOpen, setMenuOpen] = useState(false)
    return (
        <div className="bg-[#383838] px-4 py-4 flex-col text-white">
            <div className="flex items-center justify-between">
                <Link to="/">
                    <img width="75" height="30" src="https://snhcloud.com/cdn/snh_no_padding.webp" alt="SNH Cloud logo"/>
                </Link>
                <div className="lg:hidden">
                    <FontAwesomeIcon icon={faBars} onClick={() => setMenuOpen(val => !val)} />
                </div>
                <div className="hidden flex-grow lg:block">
                    <NavLinks />
                </div>
            </div>
            {MenuOpen &&
                <div className="lg:hidden mt-8">
                    <NavLinks mobile setNavState={setMenuOpen}/>
                </div>
            }
        </div>
    )
}

export default TopNav