import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { ThemeProvider, createTheme } from '@mui/material'
import AppWrapper from './AppWrapper'
import { ReactQueryDevtools } from 'react-query/devtools'
import { QueryClient, QueryClientProvider } from 'react-query'

const darkTheme = createTheme({
	palette: {
		mode: 'dark',
		primary: {
			main: '#3B82F6',
		},
		info: {
			main: '#0866D2',
			dark: '#0866D2',
		},
	},
})
const queryClient = new QueryClient()

ReactDOM.render(
	<React.StrictMode>
		<QueryClientProvider client={queryClient}>
			<ThemeProvider theme={darkTheme}>
				<AppWrapper />
			</ThemeProvider>
			<ReactQueryDevtools initialIsOpen={false} />
		</QueryClientProvider>
	</React.StrictMode>,
	document.getElementById('root'),
)
