const Refund = () => {
	return (
		<>
			<div className='min-h-screen flex items-center justify-center bg-trueGray-900 py-12 px-4 sm:px-6 lg:px-8'>
				<div className='bg-trueGray-900 min-h-screen m-auto text-gray-200 font-bold text-center'>
					<div className='font-extrabold text-6xl text-white mb-5'>Refund and Cancellation policy</div>
					<div className='my-10 mx-8'>
						At snhcloud.com, our goal is to ensure customer satisfaction. If you are not entirely happy with
						our service, you may be eligible for a refund. Please review our refund policy below:
					</div>
					<div>To request a refund, please contact us via a channel mentioned in "Contact Us" page</div>
					<div>For accidental purchases this must be done within 12 hours of purchase</div>
					<div>
						Refunds will be denied if the customer claims a lack of knowledge about how to service works
					</div>
					<div>
						We do not have a Official SLA, but depending on downtime if any prorata credits might be
						refunded to compensate
					</div>
					<div>The refund will be processed back to the orginal payment method or as account credits</div>
				</div>
			</div>
		</>
	)
}

export default Refund
