import { lazy, Suspense } from 'react'
import { createEmotionCache, MantineProvider } from '@mantine/core'
import App from './App'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import NotFound from './componets/NotFound'
import AuthCallback from './componets/Auth/AuthCallback'
const Home = lazy(() => import('./componets/Home'))
import Login from './componets/Auth/Login'
import ContactUs from './componets/Legal/contactus'
import { Notifications } from '@mantine/notifications'
import Spinner from './componets/elements/Spinner'
import LegalRouter from './routers/LegalRouter'
import GamePanelFeatures from './componets/GameServers/GamePanelFeatures'
import ServersList from './componets/ServersList/ServersList'
import ClaimFreeGameServers from './pages/ClaimFreeGameServers'
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from './components/ui/select'
import { ThemeProvider } from './components/themeprovider'
const DashboardRouter = lazy(() => import('./routers/DashboardRouter'))
const HostingOrderRouter = lazy(() => import('./routers/HostingOrderRouter'))

const AppWrapper = () => {
	const appendCache = createEmotionCache({ key: 'mantine', prepend: false })
	return (
		<ThemeProvider>
			<MantineProvider emotionCache={appendCache} withGlobalStyles withNormalizeCSS theme={{ colorScheme: 'dark' }}>
				<Notifications />
				<BrowserRouter>
					<Suspense fallback={<Spinner />}>
						<Routes>
							<Route path='/authcallback' element={<AuthCallback />} />
							<Route path='/' element={<App />}>
								<Route path='' element={<Home />} />
								<Route path='contactus' element={<ContactUs />} />
								<Route path='game-servers-list' element={<ServersList />} />
								<Route path='game-panel-features' element={<GamePanelFeatures />} />
								{/* <Route path='game-server-query-checker' element={<GameServerQueryChecker />} /> */}
								<Route path="/hosting/free-game-servers" element={<ClaimFreeGameServers />} />
								<Route path='/hosting/*' element={
									<Suspense fallback={<Spinner />}>
										<HostingOrderRouter />
									</Suspense>
								} />
								<Route path='/legal/*' element={
									<Suspense fallback={<Spinner />}>
										<LegalRouter />
									</Suspense>
								} />
								<Route path='login' element={<Login />} />
								<Route path='/dashboard/*' element={
									<Suspense fallback={<Spinner />}>
										<DashboardRouter />
									</Suspense>
								} />
							</Route>
							<Route element={<NotFound />} />
						</Routes>
					</Suspense>
				</BrowserRouter>
			</MantineProvider>
		</ThemeProvider>
	)
}

export default AppWrapper
