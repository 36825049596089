import { Link } from "react-router-dom"
const NotFound = () => {
    return (
        <div className="bg-trueGray-900 m-auto text-gray-200 font-bold text-center">
        Not Found 
        <br />
        <Link to="/">Take me back to safety w/o reload</Link>
        </div>
    )
}

export default NotFound;