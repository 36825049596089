import { TestServer } from "./ServersList"

interface ServerListCardProps {
    ts: TestServer
}

const ServerListCard = ({ts}: ServerListCardProps) => {
    return (
        <a href={ts.Link ? ts.Link : ""} target="_blank" className="flex items-center justify-between rounded-sm bg-trueGray-800 px-4 py-4 gap-4 w-[350px] max-w-screen">
            <div className="flex flex-col gap-1">
                <img src={`https://game.snhcloud.com/assets/gameicons/${ts.Type}.svg`} className="h-10" />
                <div className="text-sm font-bold text-trueGray-600">{ts.Type.toUpperCase()}</div>
            </div>
            <div className="flex flex-col items-center">
                <div className="font-medium">
                    {ts.Name || ts.Type.toUpperCase()}
                </div>
                <div className="text-sm font-medium text-gray-400">
                    {ts.Endpoint}
                </div>
            </div>
            <img className="h-5" src={`https://snhcloud.com/cdn/assets/country-banners/${ts.Location}.svg`} />
        </a>
    )
}

export default ServerListCard